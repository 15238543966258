import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import withAuth from "../../auth";
import Pagination from "../Pagination";
import moment from 'moment';
import { API_URL } from '../../constants';
import {Link, useNavigate} from "react-router-dom";
import {Breadcrumb} from "react-bootstrap";
import {AuthContext} from "../AuthContext";

const DriverList = () => {
    const [drivers, setDrivers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortBy, setSortBy] = useState('id');
    const [sortOrder, setSortOrder] = useState('desc');
    const [filters, setFilters] = useState({
        // id: '',
        // email: '',
        // phone_num: '',
        // created_at_start_date: '',
        // created_at_end_date: '',
        // admin_approved: '',
        // verified: '',
        // name: '',
        // truck_type_id: '',
        // onboarding_state: '',
        // company_id: '',
    });
    const navigate = useNavigate();
    const {token, setToken} = useContext(AuthContext);

    useEffect(() => {
        fetchDrivers();
    }, [currentPage, sortBy, sortOrder]);

    const fetchDrivers = async (newFilters = null) => {
        setLoading(true);
        try {
            const response = await axios.post(`${API_URL}/api/admin/drivers`,
                {
                    sort_by: sortBy,
                    sort_order: sortOrder,
                    page: currentPage,
                    ...(newFilters || filters),
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            setDrivers(response.data.data);
            setTotalPages(response.data.last_page);
        } catch (error) {
            console.error("There was an error fetching the drivers!", error);
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSortChange = (column) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortOrder('asc');
        }
        setCurrentPage(1); // reset pagination when sort
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleFilterChange = (e, column) => {
        const newFilters = {
            ...filters,
            [column]: e.target.value,
        };

        const nonEmptyFilters = Object.keys(newFilters).reduce((acc, key) => {
            if (newFilters[key]) {
                acc[key] = newFilters[key];
            }
            return acc;
        }, {});

        setFilters(nonEmptyFilters);
    };
    const handleSearchSubmit = () => {
        setCurrentPage(1); // reset pagination when filter
        fetchDrivers();
    };

    const handleDropdownChange = (e, column) => {
        const newFilters = {
            ...filters,
            [column]: e.target.value,
        };

        const nonEmptyFilters = Object.keys(newFilters).reduce((acc, key) => {
            if (newFilters[key]) {
                acc[key] = newFilters[key];
            }
            return acc;
        }, {});

        setFilters(nonEmptyFilters);
        setCurrentPage(1); // reset pagination when filter
        fetchDrivers(nonEmptyFilters);
    };

    return (
        <div className="container-fluid mt-3">
            <h2 className="text-center">Driver List</h2>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className="table-responsive">
                    <table className="table table-sm table-striped text-center text-nowrap">
                        <thead>
                        <tr>
                            <th onClick={() => handleSortChange('id')}>
                                ID / Comp. <i className={`fa fa-sort${sortBy === 'id' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('name')}>
                                Name <i className={`fa fa-sort${sortBy === 'name' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('email')}>
                                Email <i className={`fa fa-sort${sortBy === 'email' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('phone_num')}>
                                Phone <i className={`fa fa-sort${sortBy === 'phone_num' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('admin_approved')}>
                                Approved <i className={`fa fa-sort${sortBy === 'admin_approved' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('onboarding_state')}>
                                Onboarding <i className={`fa fa-sort${sortBy === 'onboarding_state' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                            <th onClick={() => handleSortChange('created_at')}>
                                Reg. / Login <i className={`fa fa-sort${sortBy === 'created_at' ? (sortOrder === 'asc' ? '-asc' : '-desc') : ''}`}/>
                            </th>
                        </tr>
                        <tr>
                            {/* ID / Comp. */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.id}
                                        placeholder='Driver ID'
                                        onChange={(e) => handleFilterChange(e, 'id')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            {/* Name */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.name}
                                        onChange={(e) => handleFilterChange(e, 'name')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            {/* Email */}
                            <td>
                                <div className="input-group">
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.email}
                                        onChange={(e) => handleFilterChange(e, 'email')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            {/* Phone */}
                            <td>
                                <div className="input-group" style={{minWidth: '140px'}}>
                                    <input
                                        className="form-control form-control-sm text-center"
                                        type="text"
                                        inputMode="search"
                                        value={filters.phone_num}
                                        placeholder='204-123-4567'
                                        onChange={(e) => handleFilterChange(e, 'phone_num')}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') handleSearchSubmit(e)
                                        }}
                                    />
                                    <button className="btn btn-sm btn-outline-secondary" onClick={handleSearchSubmit}>
                                        <i className="fa fa-search" />
                                    </button>
                                </div>
                            </td>
                            {/* Approved */}
                            <td>
                                <div className="input-group">
                                    <select
                                        className="form-control form-control-sm text-center"
                                        value={filters.admin_approved}
                                        onChange={(e) => handleDropdownChange(e, 'admin_approved')}
                                    >
                                        <option value="">All</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                </div>
                            </td>
                            {/* Onboarding */}
                            <td>
                                <div className="input-group">
                                    <select
                                        className="form-control form-control-sm text-center"
                                        value={filters.onboarding_state}
                                        onChange={(e) => handleDropdownChange(e, 'onboarding_state')}
                                    >
                                        <option value="">All</option>
                                        <option value="1">Registered</option>
                                        <option value="2">Chose a truck type</option>
                                        <option value="3">Created vehicle</option>
                                        <option value="4">Uploaded vehicle photo</option>
                                        <option value="5">Uploaded license front</option>
                                        <option value="6">Uploaded license back</option>
                                        <option value="7">Uploaded insurance</option>
                                        <option value="8">Uploaded proof of work</option>
                                        <option value="9">Received invite background check email</option>
                                        <option value="10">Applicant submitted background check to the Certn</option>
                                        <option value="11">Applicant was approved by the Certn</option>
                                    </select>
                                </div>
                            </td>
                            {/* ... other filters ... */}
                        </tr>
                        </thead>
                        <tbody className="text-wrap">
                        {drivers.map((driver) => (
                            <tr key={driver.id}>
                                <td className={driver.admin_approved ? '' : 'table-danger'}>
                                    <Link to={`/drivers/${driver.id}`}>
                                        {driver.id}
                                    </Link>
                                    <br/>
                                    {driver.company ? driver.company.business_name : ''}
                                </td>
                                <td>
                                    {driver.profile_photo && <div><img src={driver.profile_photo} alt="Profile" height={50} /></div> }
                                    {driver.name}
                                </td>
                                <td>{driver.email}</td>
                                <td>
                                    {driver.phone_num}
                                    {driver.sms_enabled === 1 && (
                                        <>
                                            <br/>
                                            <span className="badge badge-info"><i className="fa fa-comments"/> SMS</span>
                                        </>
                                    )}
                                </td>
                                <td>{driver.admin_approved ? 'Yes' : 'No'}</td>
                                <td>{driver.onboarding_state.name}</td>
                                <td>
                                    {moment(driver.created_at).format('YYYY-MM-DD HH:mm')}
                                    <div>
                                        {driver.last_login_at ? moment(driver.last_login_at).format('YYYY-MM-DD HH:mm') : 'unknown'}
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {totalPages > 1 && currentPage <= totalPages && (
                        <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange}/>
                    )}
                </div>
            )}
        </div>
    );
};

export default withAuth(DriverList);
