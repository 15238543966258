import React, { useEffect, useState } from 'react';
import { GoogleMap, DirectionsRenderer } from '@react-google-maps/api';
import { useGoogleMaps } from './GoogleMapsContext';

const NPMapViewDirections = (props) => {
    const { isLoaded, loadError } = useGoogleMaps();
    const [directions, setDirections] = useState(null);
    const [mapCenter, setMapCenter] = useState({ lat: 49.8951, lng: -97.1384 }); // Initial center of the map
    const [shouldCenter, setShouldCenter] = useState(true); // Control if the map should re-center

    useEffect(() => {
        if (isLoaded && props?.origins?.length && props?.dropoffs?.length) {
            const directionsService = new window.google.maps.DirectionsService();

            // Exclude the origin and destination from waypoints
            const waypoints = [
                ...props.origins.slice(1),
                ...props.dropoffs.slice(0, -1),
            ].map((point) => ({
                location: new window.google.maps.LatLng(point.latitude, point.longitude),
                stopover: true,
            }));

            const request = {
                origin: new window.google.maps.LatLng(props.origins[0].latitude, props.origins[0].longitude),
                destination: new window.google.maps.LatLng(
                    props.dropoffs[props.dropoffs.length - 1].latitude,
                    props.dropoffs[props.dropoffs.length - 1].longitude
                ),
                waypoints: waypoints,
                travelMode: window.google.maps.TravelMode.DRIVING,
                optimizeWaypoints: true,
            };

            directionsService.route(request, (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    setDirections(result);

                    const optimizedWaypoints = result.routes[0].waypoint_order.map((index) => waypoints[index]);
                    if (props.onWaypointsOptimized) {
                        props.onWaypointsOptimized(optimizedWaypoints);
                    }

                    if (props.onDistanceCalculated) {
                        const totalDistance = result.routes[0].legs.reduce((acc, leg) => acc + leg.distance.value, 0);
                        const distanceInKm = totalDistance / 1000;
                        props.onDistanceCalculated(distanceInKm);
                    }

                    // Update map center only if necessary
                    if (shouldCenter) {
                        setMapCenter({
                            lat: props.origins[0].latitude,
                            lng: props.origins[0].longitude,
                        });
                        setShouldCenter(false); // Stop re-centering after first render
                    }
                } else {
                    console.error("Error fetching directions:", status);
                }
            });
        }
    }, [isLoaded, props.origins, props.dropoffs]);

    useEffect(() => {
        // If routes change, allow the map to re-center
        setShouldCenter(true);
    }, [props.origins, props.dropoffs]);

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <GoogleMap
            mapContainerStyle={{ width: '100%', height: '400px' }}
            center={mapCenter}
            zoom={13}
        >
            {directions && (
                <DirectionsRenderer directions={directions} />
            )}
        </GoogleMap>
    );
};

export default NPMapViewDirections;
