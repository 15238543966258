import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { API_URL } from '../../constants';
import {Link, useNavigate, useParams} from 'react-router-dom';
import withAuth from "../../auth";
import { Breadcrumb } from 'react-bootstrap';
import moment from "moment";
import Thumbnail from "../Thumbnail";
import {AuthContext} from "../AuthContext";
import Spinner from "react-bootstrap/Spinner";

const DriverProfile = () => {
    const { driverId } = useParams();
    const [driver, setDriver] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingSms, setLoadingSms] = useState(false);
    const navigate = useNavigate();
    const {token, setToken, hasPermission} = useContext(AuthContext);
    const [notificationTitle, setNotificationTitle] = useState('');
    const [notificationBody, setNotificationBody] = useState('');
    const [isNotificationSent, setIsNotificationSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [isNewPasswordSubmitted, setIsNewPasswordSubmitted] = useState(false);

    // Fetch the details of the driver
    useEffect(() => {
        axios.get(`${API_URL}/api/admin/drivers/${driverId}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(response => {
                setDriver(response.data.data);
            })
            .catch(error => {
                console.error('There was an error fetching the driver details!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
            });
    }, [driverId]);

    const handleApproveClick = () => {
        setLoading(true);
        axios.put(`${API_URL}/api/admin/drivers/${driverId}/approval-status`, {},{
            params: {
                status: 'approve',
            },
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                alert(response.data.message);
                setDriver(response.data.driver);
            })
            .catch(error => {
                console.error('There was an error updating the approval status!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleResetApprovalClick = () => {
        setLoading(true);
        axios.put(`${API_URL}/api/admin/drivers/${driverId}/approval-status`, {},{
            params: {
                status: 'reset',
            },
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                alert(response.data.message);
                setDriver(response.data.driver);
            })
            .catch(error => {
                console.error('There was an error updating the approval status!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleEnableSmsClick = () => {
        setLoadingSms(true);
        axios.put(`${API_URL}/api/admin/drivers/${driverId}/sms-status`, {},{
            params: {
                status: 'enable',
            },
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                alert(response.data.message);
                setDriver(response.data.driver);
            })
            .catch(error => {
                console.error('There was an error enabling SMS notifications!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
            })
            .finally(() => {
                setLoadingSms(false);
            });
    };

    const handleDisableSmsClick = () => {
        setLoadingSms(true);
        axios.put(`${API_URL}/api/admin/drivers/${driverId}/sms-status`, {},{
            params: {
                status: 'disable',
            },
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                alert(response.data.message);
                setDriver(response.data.driver);
            })
            .catch(error => {
                console.error('There was an error disabling SMS notifications!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
            })
            .finally(() => {
                setLoadingSms(false);
            });
    };

    const handleDeleteClick = () => {
        if (window.confirm("Are you sure that you want to delete this driver?")) {
            axios.delete(`${API_URL}/api/admin/drivers/${driverId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            })
                .then(response => {
                    alert('Driver deleted successfully!');
                    setDriver(null);
                    navigate('/drivers');
                })
                .catch(error => {
                    console.error('There was an error deleting the driver!', error);
                    if (error.response && error.response.status === 401) {
                        localStorage.removeItem('authToken');
                        setToken(null);
                        navigate('/');
                    }
                });
        }
    };

    const handleNewPasswordClick = async () => {
        if (!window.confirm("Could you please confirm if you would like to change the password for the driver?")) return false;

        setErrorMessage('');
        setIsNewPasswordSubmitted(true);
        try {
            const response = await axios.put(`${API_URL}/api/admin/drivers/${driverId}/password`, {password: newPassword}, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            alert(response.data.message);
            setNewPassword('');
        }
        catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('authToken');
                setToken(null);
                navigate('/');
            }
            if (error?.response?.data?.errors) {
                const errors = error.response.data.errors;
                const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                setErrorMessage(errorMessages);
            } else if (error?.response?.data?.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('An error occurred. Please try again later.');
            }
        }
        finally {
            setIsNewPasswordSubmitted(false);
        }
    };

    const handleSendPushNotification = () => {
        if (!window.confirm("Are you sure that you want to send the push notification to this driver?")) return false;

        setErrorMessage('');
        setIsNotificationSent(true);
        axios.post(`${API_URL}/api/admin/drivers/${driverId}/notify`, {title: notificationTitle, body: notificationBody}, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                alert('Push notification successfully sent!');
                setNotificationTitle('');
                setNotificationBody('');
            })
            .catch(error => {
                console.error('There was an error sending the push notification to the driver!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }

                if (error?.response?.data?.errors) {
                    const errors = error.response.data.errors;
                    const errorMessages = Object.keys(errors).map(key => errors[key].join(' ')).join(' ');
                    setErrorMessage(errorMessages);
                } else if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage('An error occurred. Please try again later.');
                }
            })
            .finally(() => {
                setIsNotificationSent(false);
            });
    };

    // Function to render stars based on rating
    const renderStars = (rating) => {
        const fullStars = Math.floor(rating);
        const halfStar = rating - fullStars >= 0.5;
        const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

        return (
            <>
                {[...Array(fullStars)].map((e, i) =>
                    <i key={`full-${i}`} className="fa fa-star" style={{ color: '#FFD700' }}></i>
                )}
                {halfStar && <i className="fa fa-star-half-o" style={{ color: '#FFD700' }}></i>}
                {[...Array(emptyStars)].map((e, i) =>
                    <i key={`empty-${i}`} className="fa fa-star-o" style={{ color: '#FFD700' }}></i>
                )}
                &nbsp;({rating.toFixed(1)})
            </>
        );
    };

    return (
        <div className="container-fluid mt-3">
            <Breadcrumb>
                <Breadcrumb.Item href="/drivers">Drivers</Breadcrumb.Item>
                <Breadcrumb.Item active>Driver Profile</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-center">Driver Profile {driver ? driver.id : ''}</h1>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            {driver ? (
                <div className="row">
                    <div className="col-md-4">
                        <div className="card mb-3">
                            <div className="card-body" align={'center'}>
                                {driver.profile_photo &&
                                    <a href={driver.profile_photo} target="_blank" rel="noopener noreferrer">
                                        <img src={driver.profile_photo} width="100" alt={`${driver.name}'s Profile Photo`} className="mb-3" />
                                    </a>
                                }
                                {driver.company && (
                                    <div className="mb-2">
                                        <h5 className="card-title">Company</h5>
                                        <p className="card-text">Business Name: <b>{driver.company.business_name}</b></p>
                                    </div>
                                )}
                                <p className="card-text">
                                    <b>{driver.name}</b><br/>
                                    <b>{driver.email}</b> <br/>
                                    <b>{driver.phone_num}</b>
                                </p>
                                <p className="card-text">Registered: <b>{moment(driver.created_at).format('YYYY-MM-DD HH:mm')}</b>
                                    <br/>
                                    Last Login: <b>{driver.last_login_at ? moment(driver.last_login_at).format('YYYY-MM-DD HH:mm') : 'unknown '}</b>
                                    <br/>
                                    IP: <b>{driver.last_login_ip || 'unknown'}</b>
                                </p>
                                {hasPermission('driver-update') && (
                                    <>
                                        <div className="d-flex align-items-center justify-content-center">
                                            {loading ? (
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            ) : (
                                                <div>
                                                    {driver.admin_approved ? (
                                                        <button className="btn btn-warning" style={{marginRight: '.5em'}} onClick={handleResetApprovalClick}>Reset Approval</button>
                                                    ) : (
                                                        <button className="btn btn-success" style={{marginRight: '.5em'}} onClick={handleApproveClick}>Approve</button>
                                                    )}
                                                </div>
                                            )}

                                            {loadingSms ? (
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            ) : (
                                                <div>
                                                    {driver.sms_enabled ? (
                                                        <button
                                                            className="btn btn-warning"
                                                            style={{ marginRight: '.5em' }}
                                                            onClick={handleDisableSmsClick}
                                                        >
                                                            <i class="fa fa-comments"/>&nbsp; Disable SMS
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-success"
                                                            style={{ marginRight: '.5em' }}
                                                            onClick={handleEnableSmsClick}
                                                        >
                                                            <i className="fa fa-comments"/>&nbsp;Enable SMS
                                                        </button>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <hr/>
                                        <div className="form-row align-items-center justify-content-center">
                                            <div className="col-auto">
                                                <input type="password" className="form-control mb-2" name="password" placeholder="New password"
                                                       value={newPassword} onChange={e => setNewPassword(e.target.value)} autoComplete="new-password"/>
                                            </div>
                                            <div className="col-auto">
                                                <button className="btn btn-dark mb-2" onClick={handleNewPasswordClick}>
                                                    {isNewPasswordSubmitted
                                                        ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;Changing...</>
                                                        : "Change"
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                        <hr/>
                                        <button className="btn btn-danger" onClick={handleDeleteClick}>Delete</button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card mb-3">
                            <div className="card-body">
                                <h5 className="text-center">Details</h5>
                                <p className="card-text">
                                    Average Rating: <b>{renderStars(driver.avg_rating || 0)}</b><br/>
                                    Onboarding State: <b>{driver.onboarding_state.name}</b>
                                    <br/>
                                    Truck Type: <b>{driver.truck_type?.title}</b>
                                    <br/>
                                    Heard About Us From: <b>{driver.how_did_you_hear_about_us?.referral_source}</b>
                                    <br/>
                                    Signup Type: <b>{driver.signup_type?.signup_type}</b>
                                    <br/>
                                    Available to Deliver: <b>{driver.available_to_deliver ? 'Yes' : 'No'}</b>
                                </p>
                            </div>
                        </div>
                        {hasPermission('driver-update') && (
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h5 className="text-center">Agreement</h5>
                                    <p className="card-text">
                                        Type:&nbsp;
                                        <b>
                                            {driver.agreement_type === 1 ? <a href="https://navipickups.com/general-driver-contractor-agreement/" target="_blank" rel="noopener noreferrer">General Contractor's Agreement</a>
                                                : driver.agreement_type === 2 ? <a href="https://navipickups.com/commercial-driver_contractor-agreement/" target="_blank" rel="noopener noreferrer">Commercial Contractor's Agreement</a>
                                                : driver.agreement_type === 3 ? <a href="https://navipickups.com/cubecargo-vans-commercial-contractor-agreement/" target="_blank" rel="noopener noreferrer">Cube&Cargo Vans Commercial Contractor's Agreement</a>
                                                : ''}
                                        </b>
                                        <br/>
                                        Name: <b>{driver.agreement_name}</b><br/>
                                        Created At: <b>{driver.agreement_created_at ? moment(driver.agreement_created_at).format('YYYY-MM-DD HH:mm') : 'unknown '}</b>
                                        <br/>
                                        {driver.agreement_signature
                                            ?
                                            <a href={driver.agreement_signature} target="_blank" rel="noopener noreferrer">
                                                <img src={driver.agreement_signature} alt="agreement_signature" style={{ width: "auto", height: "auto"}} />
                                            </a>
                                            : 'Not Uploaded'}
                                    </p>
                                </div>
                            </div>
                        )}
                        <div className="col-md-4">
                        </div>
                    </div>
                    <div className="col-md-4">
                        <VehicleList driverId={driverId} />

                        {hasPermission('driver-update') && (
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h5 className="text-center">Send Push Notification</h5>
                                    <div className="form-group mb-2">
                                        <input className="form-control" type="text" placeholder="Title" value={notificationTitle}
                                               onChange={(e) => setNotificationTitle(e.target.value)} />
                                    </div>
                                    <div className="form-group mb-2">
                                        <textarea className="form-control" style={{minHeight: '50px'}} placeholder="Body" value={notificationBody}
                                                  onChange={(e) => setNotificationBody(e.target.value)} />
                                    </div>
                                    <div align={'center'}>
                                        <button className="btn btn-primary"onClick={handleSendPushNotification}>
                                            {isNotificationSent
                                                ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;Sending...</>
                                                : 'Send'
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {hasPermission('driver-update') && (
                        <div className="col-12">
                        <h5 className="text-center">Documents</h5>
                        <div className="row">
                            <div className="col-6 mb-2">
                                <div className="card p-2" align="center">
                                    Front License <br/> {driver.driver_front_license
                                    ?
                                    <a href={driver.driver_front_license} target="_blank" rel="noopener noreferrer">
                                        <Thumbnail fileUrl={driver.driver_front_license} alt="driver_front_license" />
                                    </a>
                                    : 'Not Uploaded'}
                                </div>
                            </div>
                            <div className="col-6 mb-2">
                                <div className="card p-2" align="center">
                                    Back License <br/> {driver.driver_back_license
                                    ?
                                    <a href={driver.driver_back_license} target="_blank" rel="noopener noreferrer">
                                        <Thumbnail fileUrl={driver.driver_back_license} alt="driver_back_license" />
                                    </a>
                                    : 'Not Uploaded'}
                                </div>
                            </div>
                            <div className="col-6 mb-2">
                                <div className="card p-2" align="center">
                                    Proof Of Work <br/> {driver.driver_proof_of_work
                                    ?
                                    <a href={driver.driver_proof_of_work} target="_blank" rel="noopener noreferrer">
                                        <Thumbnail fileUrl={driver.driver_proof_of_work} alt="driver_proof_of_work" />
                                    </a>
                                    : 'Not Uploaded'}
                                </div>
                            </div>
                            <div className="col-6 mb-2">
                                <div className="card p-2">
                                    <p className="card-text" align="center">
                                        Insurance <br/> {driver.driver_insurance
                                        ?
                                        <a href={driver.driver_insurance} target="_blank" rel="noopener noreferrer">
                                            <Thumbnail fileUrl={driver.driver_insurance} alt="driver_insurance" />
                                        </a>
                                        : 'Not Uploaded'}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            ) : (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
        </div>
    );
};

const VehicleList = ({ driverId }) => {
    const [vehicles, setVehicles] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {token, setToken} = useContext(AuthContext);

    // Fetch vehicles of the driver
    useEffect(() => {
        setLoading(true);
        const token = localStorage.getItem('authToken');
        axios.get(`${API_URL}/api/admin/drivers/${driverId}/vehicles`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(response => {
                setVehicles(response.data.data);
            })
            .catch(error => {
                console.error('There was an error fetching the vehicles!', error);
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    setToken(null);
                    navigate('/');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [driverId]);

    return (
        <div className="card mb-3">
            <div className="card-body">
                <h5 className="text-center">Vehicles</h5>
                {loading ? (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div className="table-responsive">
                        <table className="table table-sm table-bordered text-center">
                            <thead>
                            <tr>
                                <th>ID / Make / Model</th>
                                <th>Truck Type</th>
                                <th>Username</th>
                            </tr>
                            </thead>
                            <tbody>
                            {vehicles.map(vehicle => (
                                <tr key={vehicle.id}>
                                    <td>
                                        <Link to={`/vehicles/${vehicle.id}`}>
                                            <b>{vehicle.id} {vehicle.make} {vehicle.model}</b>
                                        </Link>
                                    </td>
                                    <td>{vehicle.truckType?.title}</td>
                                    <td>{vehicle.username}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default withAuth(DriverProfile);
